<template>
  <b-card>
    <div v-if="aboutData && aboutData.data" class="mt-2 text-center">
      <h5 class="text-capitalize mb-75">
        <p>Sizning cashback summangiz:</p>
      </h5>
      <b-card-text class="text-center">
        <h4 class="text-center">
          <span class="display-4">
            {{ aboutData.data | formatPrice }}
          </span>
          so'm
        </h4>
      </b-card-text>
    </div>
    <div v-else class="text-center">
      <h5 class="text-center lh-base">
        Assalomu alaykum, Hurmatli Mijoz! <br />
        'SALOMHAYOT'dagi jamg'arilgan bonusingizni tekshirish uchun pasport
        ma'lumotlaringizni kiriting
      </h5>
      <b-button
        variant="primary"
        class="btn-tour-skip mb-1"
        @click="$emit('callInfo')"
      >
        Tekshirish
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
