<template>
  <div id="user-profile">
    <save ref="saveForm" @onSuccess="getItems" />
    <profile-header :header-data="profileData.header" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row class="">
        <b-col lg="12" cols="12" order="2" order-lg="1">
          <profile-about :about-data="bonusInfo" @callInfo="openModal" />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Save from "./save.vue";
import ProfileHeader from "./ProfileHeader.vue";
import ProfileAbout from "./ProfileAbout.vue";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    Save,
    ProfileHeader,
    ProfileAbout,
  },
  data() {
    return {
      profileData: {},
      bonusInfo: null,
    };
  },
  created() {
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
  mounted() {
    this.$refs.saveForm.visible = true;
  },
  methods: {
    openModal() {
      this.$refs.saveForm.visible = true;
    },
    getItems(data) {
      this.bonusInfo = data;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
