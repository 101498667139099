<template>
  <b-modal
    v-model="visible"
    no-close-on-backdrop
    hide-footer
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer ref="form" #default="{ invalid }">
      <b-form class="auth-login-form mt-2">
        <b-form-group :label="$t('Pasport seriya')" label-for="pasport">
          <validation-provider
            #default="{ errors }"
            name="pasport"
            rules="required"
          >
            <b-form-input
              id="pasport"
              v-model="form.passport"
              v-mask="'AA#######'"
              :state="errors.length > 3 ? false : null"
              placeholder="AA0000000"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('Tug`ilgan kun')" label-for="sana">
          <validation-provider
            #default="{ errors }"
            name="sana"
            rules="required"
          >
            <x-date-picker
              id="sana"
              v-model="form.birth_date"
              :state="errors.length > 0 ? false : null"
              name="sana"
              :placeholder="$t('Sana')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          variant="primary"
          class="float-right btn-tour-skip mb-1"
          @click="save"
        >
          Tekshirish
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { showToast } from "@/utils/toast";
import { required } from "@validations";
import { mapActions } from "vuex";
import { clearObject } from "@/utils";

export default {
  name: "Create",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        passport: null,
        birth_date: null,
      },
      title: "",
      visible: false,
      required,
    };
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form);
        }, 200);
      }
    },
  },
  mounted() {
    this.title = this.$t("Passport ma`lumotlaringizni kiriting");
    this.$root.$on("add", (data) => {
      this.visible = true;
    });
  },
  methods: {
    async save() {
      const valid = await this.validationForm();
      if (valid) {
        this.method(this.form)
          .then((res) => {
            // showToast(
            //   'success',
            //   this.$t('Успешно сохранено'),
            //   'CheckCircleIcon',
            // )
            this.$emit("onSuccess", res);
            this.visible = false;
          })
          .catch((err) => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors);
            }
            showToast("danger", this.$t("Ошибка"), "XIcon");
          });
      } else {
        showToast("warning", this.$t("Заполните необходимые поля"));
      }
    },
    edit(item) {
      this.form.id = item.id;
      this.form.title_uz = item.title_uz;
      this.form.body_uz = item.body_uz;
      this.visible = true;
    },
    method(data) {
      return this.getClientCashback(data);
    },
    async validationForm() {
      let validated = false;
      await this.$refs.form.validate().then((success) => {
        validated = success;
      });
      return validated;
    },
    ...mapActions({
      getClientCashback: "client/clientCashback",
    }),
  },
};
</script>

<style scoped></style>
